import { type RefObject, useEffect } from 'react';

export default function useClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callback: (event: MouseEvent) => void,
) {
  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target) return;

      const isOutsideClick = ref.current && !ref.current.contains(target);

      if (!isOutsideClick) return;

      callback(e);
    };

    document.addEventListener('click', onClickOutside);

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);
}
