import i18next, { t } from 'i18next';
import { FC } from 'react';

import styles from './Header.module.scss';

import useScrollWindowStatus from '#hooks/useScrollWindowStatus';
import Hamburger from '#components/menu/Hamburger/index';
import LanguageSelector from '#components/menu/LanguageSelector/index';
import Navigation from '#components/menu/Navigation/index';
import Button from '#components/ui/Button/index';
import { initI18Next } from '#src/utils/I18nextInit';
import { prepareUrl } from '#utils/prepareUrl';
import LogoLink from '#components/ui/LogoLink';

initI18Next(i18next, 'en');

type Props = {
  showLanguageSelector: boolean;
  currentLanguage: string;
  logoLink?: string;
  pathname: string;
};

const Header: FC<Props> = ({ showLanguageSelector, currentLanguage, pathname }) => {
  const isScrolled = useScrollWindowStatus();

  initI18Next(i18next, currentLanguage);

  return (
    <header id="header" className={`${styles.header} ${isScrolled && styles.isScrolled}`}>
      <div className={styles.container}>
        <LogoLink style={styles.logo} currentLanguage={currentLanguage} />
        <div className={styles.menu}>
          <Navigation pathname={pathname} lang={currentLanguage} />
          {showLanguageSelector && <LanguageSelector lang={currentLanguage} />}
          <Hamburger />
          <Button
            text={t('buttons.login')}
            styleType="lightDesktop"
            eventName="login"
            onClick={() => (
              window.open(prepareUrl('/login', currentLanguage) || '/login'), '_blank'
            )}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
