import { t } from 'i18next';

import styles from './Navigation.module.scss';
import { prepareUrl } from '#utils/prepareUrl';
import analytics from '#utils/analytics';
import { openFeature } from '#store/featureData';
import { useStore } from '@nanostores/react';

const FEATURES_LIST = [
  { title: 'locationByNumber', link: '/', eventLabel: 'location-by-number' },
  {
    title: 'reversePhoneLookup',
    link: '/reverse-number-lookup/',
    eventLabel: 'reverse-number-lookup',
  },
  {
    title: 'reverseUsernameLookup',
    link: '/reverse-username-lookup/',
    eventLabel: 'reverse-username-lookup',
  },
  { title: 'findLostPhone', link: '/find-my-lost-phone/', eventLabel: 'find-my-lost-phone' },
];

type Props = {
  lang: string;
};

const FeaturesSelectorList = ({ lang }: Props) => {
  const $openFeature = useStore(openFeature);
  const { pathname } = window.location;

  const handleClickFeature = (label: string) => {
    analytics.event({ action: 'features' });
    analytics.event({ action: 'Landing', event_label: label });
  };

  const checkIfActive = (feature: Record<string, string>) => {
    const langPath = lang === 'en' ? '' : lang + '/';

    if (pathname.replace(langPath, '') === '/') {
      openFeature.set('location-by-number');
    }

    if (pathname.includes(feature.eventLabel)) {
      openFeature.set(feature.eventLabel);
    }

    return $openFeature.includes(feature.eventLabel);
  };

  return (
    <ul className={styles.features_list}>
      {FEATURES_LIST.map((feature) => (
        <li
          key={feature.title}
          className={`${styles.features_item} ${checkIfActive(feature) && styles.active}`}
        >
          <a
            href={prepareUrl(feature.link, lang)}
            className={styles.features_link}
            onClick={() => handleClickFeature(feature.eventLabel)}
          >
            {t(`header.${feature.title}`)}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default FeaturesSelectorList;
