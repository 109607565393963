import styles from './Navigation.module.scss';

import Button from '#components/ui/Button/index';

import { prepareUrl } from '#utils/prepareUrl';
import { t } from 'i18next';
import { useStore } from '@nanostores/react';
import useResponsive from '#hooks/useResponsive';
import { openFeatures, openHamburger } from '#store/headerNavigation';
import { scrollToIdElement } from '#utils/scroll';
import FeaturesSelectorList from '#components/menu/Navigation/FeaturesSelectorList';

type Props = {
  pathname: string;
  lang: string;
};

const Navigation = ({ pathname, lang }: Props) => {
  const preparedLink = prepareUrl('/login', lang);
  const $openHamburger = useStore(openHamburger);
  const $openFeatures = useStore(openFeatures);
  const isDesktop = useResponsive('desktop');
  const otherPages = ['terms', 'policy', '404', 'check-lost-phone'];

  const toggleFeatures = (status: boolean) => {
    openFeatures.set(status);
  };

  const handleCloseHamburger = (element: string) => {
    otherPages.forEach((el) => {
      if (pathname.includes(el)) {
        window.location.assign(prepareUrl(`/#${element}`, lang, false));
      } else {
        scrollToIdElement(element);
      }
    });
    toggleFeatures(false);
    openHamburger.set(false);
  };

  return (
    <ul
      className={`${styles.navigation} ${$openHamburger || $openFeatures ? styles.isOpened : ''}`}
    >
      <li className={styles.list} role="presentation" onClick={() => handleCloseHamburger('why')}>
        <a href="/#why" className={styles.link} onClick={(e) => e.preventDefault()}>
          {t('header.why')}
        </a>
      </li>
      <li
        className={`${styles.list} ${styles.features} ${$openFeatures ? styles.open : ''}`}
        onMouseEnter={() => isDesktop && toggleFeatures(true)}
        onMouseLeave={() => isDesktop && toggleFeatures(false)}
      >
        <span
          className={`${styles.link} ${styles.features_title}`}
          role="presentation"
          onClick={() => toggleFeatures(!$openFeatures)}
        >
          {t('header.features')}
        </span>
        {$openFeatures && <FeaturesSelectorList lang={lang} />}
      </li>
      <li
        className={styles.list}
        role="presentation"
        onClick={() => handleCloseHamburger('questions')}
      >
        <a href="/#questions" className={styles.link} onClick={(e) => e.preventDefault()}>
          {t('header.faq')}
        </a>
      </li>

      <li className={styles.list}>
        <a
          href={`${t('footer.blogLink')}`}
          target="_blank"
          className={styles.link}
          rel="noreferrer"
        >
          {t('header.blog')}
        </a>
      </li>

      <li className={`${styles.list} ${styles.list_button} ${styles.mobGreen}`}>
        <Button
          text={t('buttons.login')}
          eventName="login"
          onClick={() => window.open(preparedLink, '_blank')}
        />
      </li>
    </ul>
  );
};

export default Navigation;
