export const localeLanguageMap: Record<string, string> = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  es: 'Español',
  it: 'Italiano',
  pl: 'Polski',
  pt: 'Português',
  tr: 'Türkçe',
  ua: 'Українська',
  ar: 'العربية',
  jp: '日本',
  id: 'Bahasa Indonesia',
  my: 'Bahasa Melayu',
  cz: 'Čeština',
  se: 'Svenska',
  dk: 'Dansk',
  th: 'ภาษาไทย',
  vi: 'Tiếng Việt',
  nl: 'Nederlands',
};

export const lostPhoneLocales = [
  'en',
  'fr',
  'de',
  'es',
  'it',
  'pl',
  'pt',
  'tr',
  'ua',
  'ar',
  'jp',
  'id',
  'nl',
];
