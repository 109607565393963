import i18next from 'i18next';

import styles from './LanguageSelector.module.scss';

import { prepareUrl } from '#utils/prepareUrl';
import { LANGS } from '#config';
import { localeLanguageMap } from '#constants/locales';

const LanguageSelector = () => {
  const currentLanguage = i18next.language;

  const changeLanguage = (lng: string) => () => {
    if (typeof document === 'undefined') return '';
    i18next.changeLanguage(lng).then(() => (document.location.href = prepareUrl(null, lng)));
  };

  const getLocaleTitle = (lng: string) => {
    return localeLanguageMap[lng];
  };

  const checkIsActive = (lng: string) => lng === currentLanguage;

  const handleActive = (active: boolean, className: string) =>
    `${className} ${active ? styles.active : ''}`;

  return (
    <ul className={`${styles.menuItems}`}>
      {LANGS.map((lng: string) => (
        <li
          onClick={changeLanguage(lng)}
          className={handleActive(checkIsActive(lng), styles.menuItem)}
          key={`${lng}_lang`}
        >
          <a href={prepareUrl(null, lng)}>{getLocaleTitle(lng)}</a>
        </li>
      ))}
    </ul>
  );
};

export default LanguageSelector;
