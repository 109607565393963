import { useEffect, useState } from 'react';

import { debounce } from '#src/utils/debounce';

const useScrollWindowStatus = () => {
  const desktopStartBreakpoint = 1170;
  const mobileScrollTriggerPosition = 1;
  const desktopScrollTriggerPosition = 20;
  const [isScrolled, setIsScrolled] = useState(false);
  let scrollPosValue: number;
  let scrollPos = typeof window !== 'undefined' ? window.scrollY : '';

  useEffect(() => {
    if (window.location.pathname.includes('check-lost-phone')) {
      setIsScrolled(true);
      return;
    }
    
    const updateScrollPos = () => {
      if (window.innerWidth <= desktopStartBreakpoint) {
        scrollPosValue = mobileScrollTriggerPosition;
      } else {
        scrollPosValue = desktopScrollTriggerPosition;
      }
    };

    window.addEventListener('resize', debounce(updateScrollPos, 250));

    const headerChangeClass = () => {
      scrollPos = window.scrollY;
      setIsScrolled(scrollPos >= scrollPosValue);
    };

    window.addEventListener('scroll', headerChangeClass);
    window.addEventListener('load', headerChangeClass);

    updateScrollPos();

    return () => {
      window.removeEventListener('resize', debounce(updateScrollPos, 250));
    };
  }, []);
  return isScrolled;
};

export default useScrollWindowStatus;
