import { useRef } from 'react';
import styles from './LanguageSelector.module.scss';
import useClickOutside from '#hooks/useClickOutside';
import LanguageSelectorList from '#components/menu/LanguageSelector/LanguageSelectorList';
import { useStore } from '@nanostores/react';
import { openHamburger, openLangSwitcher, openFeatures } from '#store/headerNavigation';
import useResponsive from '#hooks/useResponsive';

type Props = {
  lang: string;
};

const LanguageSelector = ({ lang }: Props) => {
  const languageSwitcherRef = useRef<HTMLDivElement>(null);
  const $openLangSwitcher = useStore(openLangSwitcher);
  const $openHamburger = useStore(openHamburger);
  const $openFeatures = useStore(openFeatures);
  const isDesktop = useResponsive('desktop');

  const toggleSwitcher = (status: boolean) => {
    openLangSwitcher.set(status);
    if ($openHamburger) openHamburger.set(false);
    if ($openFeatures) openFeatures.set(false);
  };

  useClickOutside(languageSwitcherRef, () => toggleSwitcher(false));

  return (
    <div
      ref={languageSwitcherRef}
      className={styles.wrapper}
      onMouseEnter={() => isDesktop && toggleSwitcher(true)}
      onMouseLeave={() => isDesktop && toggleSwitcher(false)}
    >
      <button className={styles.switchButton} onClick={() => toggleSwitcher(!$openLangSwitcher)}>
        <span className={styles.activeLang}>{lang}</span>
      </button>
      <div className={`${styles.langWrapper} ${$openLangSwitcher && styles.isOpened}`}>
        {$openLangSwitcher && <LanguageSelectorList />}
      </div>
    </div>
  );
};

export default LanguageSelector;
