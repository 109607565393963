import styles from './Hamburger.module.scss';
import { useRef } from 'react';
import { useStore } from '@nanostores/react';
import { openHamburger, openFeatures } from '#store/headerNavigation';

const Hamburger = () => {
  const bodyRef = useRef<HTMLBodyElement>(null);
  const $openHamburger = useStore(openHamburger);

  const toggle = () => {
    openFeatures.set(false);
    openHamburger.set(!$openHamburger);

    if (bodyRef.current && !$openHamburger) bodyRef.current.style.overflowY = 'hidden';
    else if (bodyRef.current && $openHamburger) bodyRef.current.style.overflowY = 'auto';
  };

  return (
    <div className={styles.menuHolder} onClick={toggle}>
      <div className={`${styles.menu} ${$openHamburger && styles.isOpened}`}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Hamburger;
